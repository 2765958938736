
.homepage{
  background-image: url("../images/turkiye.png");
  background-position: 10% 60%;
  background-position-x: 10%;
  background-position-y: 60%;
  // background-attachment: scroll;
  // background-size: cover;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  // position: relative;
  // top: 0;
  // bottom: 0;
  // height: 100vh;
  // width: 100%;
  // background-position: 12%;

  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.propic {
  visibility:hidden
}

@media screen and (max-width:767px) {
  .homepage {
    background-attachment: scroll;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    background-position: 100% 60%;
    background-position-x: 100%;
    background-position-y: 60%;
    background-image: none;
    background-color: rgba(207, 135, 119, 1);
  }
}

@media screen and (max-width:767px) {
  .propic {
    top:5vh;
    visibility: visible;
    text-align: center;
    padding-top: 3vh;
  }
}
