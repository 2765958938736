/*
 * CSS Code skeleton by Chris Sevilleja, editted by Saaqeb.
 * Visit his codepen: https://codepen.io/chrisoncode
 */

.contact-bar {
  height: 96px;
  margin: 0 auto;
  left: 22px;

  span {
    margin: 20px 30px 20px 20px;
    opacity: 0.75;
    // filter: alpha(opacity=60);
  }
  span:hover {
    color: #ffffff;
    opacity: 2;
    filter: alpha(opacity=200);
  }
}

.form {
  margin-left: 25px;
}

.error {
  display: none;
}

.contact {
  // top: 200px;
  // background-color: rgb(39,110,138);
  background-color: rgba(207, 135, 119, 1);
  width: 100%;
  height: 100vh;
  z-index: 4;
}

.contact-contain {
  margin: 0 auto;
  width: 297px;
  display: absolute;
  top: 40%;       // change this when working with the form
}

.contact-contain h2 {
  text-align: center;
  margin-bottom:50px;
  color: #ffffff;
  font-size: 38px;
}

@media screen and (max-width: 700px){

}

@media screen and (max-width: 343px){

}

.submit {
  font-family: Heebo;
  -moz-border-radius: 27px;
  -webkit-border-radius: 27px;
  border-radius: 27px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.submit:hover {
  opacity: 2;
  filter: alpha(opacity=200);
}

/* form starting stylings ------------------------------- */
.group 			  {
  position:relative;
  margin-bottom:45px;
}

.form input {
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:300px;
  border:none;
  border-bottom:1px solid #fff;
}
.form input[type=text] {
  background-color: inherit;
  color: white;
}

.form input[type=email] {
  background-color: inherit;
  color: white;
}

.form input:focus {
  outline:none;
}

/* LABEL ======================================= */
.form label {
  color:#fff;
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}

/* active state */
.form input:focus ~ label, input:valid ~ label {
  top:-20px;
  font-size:14px;
  color:#D0D0D0;
}

.form input:focus ~ label, input:invalid ~ label {
  top:-20px;
  font-size:14px;
  color:#D0D0D0;
}

/* BOTTOM BARS ================================= */
.bar {
  position:relative;
  display:block;
  width:300px;
}
.bar:before, .bar:after {
  content:'';
  height:2px;
  width:0;
  bottom:1px;
  position:absolute;
  background:#fff;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%;
}

.form input:not(input:empty) ~ .bar, input:invalid ~ .bar {
  background: red;
}

/* active state */
.form input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position:absolute;
  height:60%;
  width:100px;
  top:25%;
  left:0;
  pointer-events:none;
  opacity:0.5;
}
