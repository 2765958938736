* {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  outline: none;
}
*:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

html,body {
	min-height: 100%;
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
	font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  background-color: #f0f0f0;
  /* font-family: "HelveticaNeue-Thin", "Helvetica Neue Thin"; */
  font-family: 'Heebo', sans-serif;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.vertical-center-parent {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
}

.hidden {
  display: none;
}
