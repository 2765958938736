
/**
Some CSS by Craig Anthony through Codepen: https://codepen.io/mcraiganthony/pen/NxGxqm?editors=1100
*/

.wrapper {
  background-color: rgb(241, 232, 230);
  z-index: 4;
  text-align: center;
  padding-bottom: 200px;
}

.portfolio {
  margin: 0 auto;
  top: 96px;
  width: 1000px;
  left: 1.5%;
  color: #696969;
  font-family: 'Roboto','Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  padding: 1rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;

  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
}

.git_link:hover {
  text-decoration: none;
}

.btn {
  background-color: white;
  border: 1px solid #cccccc;
  //border-radius: 1rem;
  color: #696969;
  padding: 0.5rem;
  text-transform: lowercase;
}

.btn:hover {
  background-color: #5b5b5b;
  color: white;
}

.btn--block {
  display: block;
  width: 100%;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards__item {
  display: flex;
  padding: 1rem;
  @media(min-width: 40rem) {
    width: 48%;
  }
  // @media(min-width: 59rem) {
  //   width: 33.3333%;
  // }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:hover {
    .card__image {
      filter: contrast(100%);
    }
  }
}

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: contrast(70%);
  //filter: saturate(180%);
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);;
  &::before {
    content: "";
	  display: block;
    padding-top: 56.25%; // 16:9 aspect ratio
  }
  @media(min-width: 40rem) {
    &::before {
      padding-top: 66.6%; // 3:2 aspect ratio
    }
  }
}

.card__title {
  color: black;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card__text {
  flex: 1 1 auto;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}

.card__buttons {
  display: flex;
  list-style-type: none;
  padding: 0 7% 0 7%;

  li {
    width: 100%;
    padding: 0 10px 0 10px;
  }
}

@media screen and (max-width: 1200px){
  .portfolio {
    width: 88%;
    left: 5%;
  }
}

@media screen and (max-width: 768px){
  .portfolio {
    left: 0;
  }
}

// @media screen and (width:375px) and (height:812px) {
//   .portfolio {

//   }
// }

.handiwork {
  font-size: 58px;
  font-weight: normal;
  top: 72px;
  color: #181818;
}

#chess {
  background-image: url('../images/chess.jpeg');
}

#search-engine {
  background-image: url('../images/search.jpeg');
  background-position: top center;
}

#tic-tac-toe {
  background-image: url('../images/tictactoe.jpg');
}

#unsplash {
  background-image: url('../images/unsplash.png');
  background-position: bottom center;
}
