
.container {
  z-index: 3;
  float: right;
  // width: 830px;
  width: 65vw;
  margin: 0;
  left: 7vw;

  position: absolute;
  top: 52.5%;
  transform: translateY(-50%);

  .intro {
    color: #ffffff;
    // width: 740.3px;
    // margin: 0;
    // right: 5vw;
    bottom: 8vh;

    h1,h2,p {
      margin: 0px;
    }

    h1 {
      font-size: 122px;
      font-weight: 200;
      text-align: left;
    }

    h2 {
      font-style: italic;
      font-size: 34px;
      font-weight: 300;
      text-align: left;
      // top: 3vh;
      top: 12px;
    }

    .info_container {
      // top: 6vh;
      top: 40px;
      float: left;

      .info_second_container {
        width: 640px;
        float: inherit;

        p {
          color: #ffffff;
          text-align: left;
          // font-family: Helvetica;
          font-weight: 400;
          font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width:767px) {
  .container {
    width: 95%;
    right: 2.5%;
    text-align: center;

    top: 60%;

      .intro {
        bottom: 0;

        h1,h2,p {
          text-align: left;
        }
        h1 {
          font-size: 13vw;
        }
        h2 {
          font-size: 5.5vw;
        }
        .info_container {
          text-align: left;
          .info_second_container {
            width: 95%;
            p {
              text-align: left;
              font-size: 2.55vh;
            }
          }
        }
      }
    }
}
